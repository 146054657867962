import React, { useState } from 'react';
import cs from 'classnames';
import Button from '../components/forms/Button';
import { DClogo } from '../components/icons';
import useScrollPosition from '../hooks/useScrollPosition';
import { footerLinks } from './Footer';

const Header = ({ current, goTo, darkBg }) => {
  const [open, setOpen] = useState(false);
  const [scroll] = useScrollPosition();
  const openHandler = (state) => {
    setOpen(state);
    setScrolling(!state);
  }

  const setScrolling = (state) => {
    const body = document.getElementsByTagName('body')[0];
    if (state) {
      body.style.overflow = 'auto';
      body.classList.toggle('menu-open')
    } else {
      body.style.overflow = 'hidden';
      body.classList.toggle('menu-open');
    }
  };

  const logoAction = () => {
    if (!goTo && typeof window !== 'undefined') {
      window.location.href = '/';
    } else {
      goTo('first');
    }
  };

  const bookingAction = () => {
    if (!goTo && typeof window !== 'undefined') {
      window.location.href = '/#book-demo';
    } else {
      goTo('last');
    }
  };


  const mobile = typeof window !== 'undefined' && window.innerWidth < 1024;

  const below = ((scroll > 0) || (current > 0)) && !mobile
  return (
    <div className={cs('dc-header', { 'dc-header-sm': below })}>
      <div>
        <DClogo onClick={() => logoAction()} />
      </div>
      <div className='hide-mobile'>
        {darkBg ? <Button label="GET YOUR CONSULTATION" action={() => bookingAction('')} outline variant="alt" /> :
          <Button label="GET YOUR CONSULTATION" hide={below} action={() => bookingAction('')} />
        }
      </div>
      <div className='show-mobile'>
        <div className={cs('dc-menu', { 'dc-menu-open': open })} onClick={() => openHandler(!open)}>
          <div>
            <DClogo onClick={() => logoAction()} color="#007495" />
          </div>
          <div>
            <h3 onClick={() => logoAction()}>HOME</h3>
            <Button label="GET YOUR CONSULTATION" action={() => bookingAction('')} outline variant="alt" />
          </div>
          <div className='dc-menu-links'>
            {footerLinks.map(link => (
              <a key={link.target} href={link.target} rel="noreferrer" target="_blank">{link.title}</a>
            ))}
          </div>
        </div>
        <div className={cs('burger', { 'burger-open': open, 'burger-scrolled': below & !open })} onClick={() => openHandler(!open)}>
          <div className='burger-inner' />
        </div>
      </div>
    </div>
  )
};

export default React.memo(Header);