import { useEffect, useState } from "react";

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [direction, setDirection] = useState('down');

  useEffect(() => {
    if (typeof window === "undefined") return;
    const updatePosition = () => {
      if (scrollPosition > (window || {}).scrollY) {
        setDirection('up');
      } else if (scrollPosition < (window || {}).scrollY) {
        setDirection('down');
      }
      setScrollPosition((window || {}).scrollY);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", updatePosition);
      }
    }
  }, [scrollPosition]);

  return [scrollPosition, direction];
};

export default useScrollPosition;
