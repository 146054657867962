import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import ogimage from '../images/BF.jpg';

import './cssreset.scss';
import './template.scss';
import Helmet from 'react-helmet';
import { GTMhelmetTag } from '../components/analytics/GTM';

const desktopMultiplier = 12;
const mobileThreshold = 1024;

const getFontSize = () => {
  if (typeof window === 'undefined') return desktopMultiplier;
  const height = window.innerHeight;
  const width = window.innerWidth;
  const isMobile = width <= mobileThreshold;
  const fontsize = isMobile ? 8 : height / 80;

  return fontsize;
}

const Template = ({ children, current, goTo, darkBg }) => {

  const [fontsize, setFontsize] = useState(getFontSize());

  let mobileSet = false;
  useEffect(() => {
    if (typeof window === "undefined") return;
    window.scroll(0, 0)
    const evt = () => {
      if (!mobileSet) {
        setFontsize(getFontSize())
        if (typeof window !== 'undefined' && window.innerWidth < 1024) {
          mobileSet = true;
        }
      }
    }
    if (typeof window !== 'undefined' && (window.innerWidth > 1024)) {
      window.addEventListener("resize", evt);
    } else {
      setFontsize(getFontSize())
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener('resize', evt);
      }
    }
  }, [])

  return (
    <div>
      <Helmet script={[
        GTMhelmetTag,
      ]} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Brandformance: Unleash the full potential of your marketing</title>
        <link rel="canonical" href="https://brandformance.dcmn.com/" />
        <meta name="description" content="Measure the combined impact of your marketing activities and find new opportunities for growth." />
        <meta property="og:title" content="Brandformance: Unleash the full potential of your marketing" />
        <meta property="og:description" content="Measure the combined impact of your marketing activities and find new opportunities for growth" />
        <meta property="og:image" content={ogimage} />
        <meta charSet="utf-8" />

      </Helmet >
      <style>{`html { font-size: ${fontsize}px !important}`}</style>
      <Header current={current} goTo={goTo} darkBg={darkBg} />
      {children}
      <Footer />

      {/* <LinkedinPixel /> */}
    </div >
  )
};

export default Template;