import React from "react";
import Socials from "../components/Socials";

const FooterElem = ({ title, subtitle, target }) => (
  <div>
    <a href={target} target="_blank" rel="noreferrer">
      <h5>{title}</h5>
      <h6>{subtitle}</h6>
    </a>
  </div>
);

export const footerLinks = [
  // {
  //   title: 'T&C',
  //   subtitle: 'Promotion rules',
  //   target: '/rules'
  // },
  {
    title: 'Legal',
    subtitle: 'Some call it imprint.',
    target: '/legal',
  },
  {
    title: 'Privacy',
    subtitle: 'Your privacy matters to us.',
    target: '/privacy',
  },
  {
    title: 'Terms',
    subtitle: 'Our house rules.',
    target: '/terms',
  }
]

const Footer = () => {
  return (
    <div className="dc-footer">
      <div className="dc-links">
        {footerLinks.map(elem => <FooterElem {...elem} key={elem.title} />)}
      </div>
      <div className="dc-cc">
        <Socials />
        <div className="dc-credits">
          Powered by www.dcmn.com<br />
          © DCMN 2022
        </div>
      </div>
    </div >
  )
}

export default Footer;