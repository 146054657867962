import React from 'react';
import { FB, IG, LI, TW, YT } from '../components/icons/Social';

const socialData = [
  {
    target: 'https://linkedin.com/company/dcmn',
    icon: LI
  },
  {
    target: 'https://www.facebook.com/DCMNglobal/',
    icon: FB,
  },
  {
    target: 'https://twitter.com/DCMN',
    icon: TW
  },
  {
    target: 'https://www.instagram.com/dcmnglobal/',
    icon: IG
  },
  {
    target: 'https://www.youtube.com/DCMN',
    icon: YT
  }
]

const Socials = () => {
  return (
    <div className='dc-socials'>
      {socialData.map(elem => (
        <a key={elem.target} href={elem.target} rel="noreferrer" target="_blank">
          <elem.icon />
        </a>
      ))}
    </div>
  )
};

export default Socials;